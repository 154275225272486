@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: "Protest Strike";
    src: url("/public/fonts/ProtestStrike-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.protest-strike-regular {
    font-family: "Protest Strike", sans-serif;
}