/* src/components/Loading.css */
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
}

.spinner {
    width: 80px;
    height: 80px;
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-top-color: #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-text {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #333;
    font-family: 'Arial', sans-serif;
}
